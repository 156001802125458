<script setup>
import { Link, useForm, Head } from '@inertiajs/vue3';
import { LoginForm } from '@goodvibes/kudoslink';
import SignupLayout from '@/Layouts/SignupLayout.vue';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};

const appName = window.document.getElementsByTagName('title')[0]?.innerText;
</script>

<template>
    <Head>
        <link rel="canonical" :href="route('login')" />
    </Head>
    <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
        {{ status }}
    </div>

    <SignupLayout pageTitle="Log in | Feedbeo">
        <template #leftColumn><span> </span></template>
        <LoginForm
            v-model:email="form.email"
            v-model:password="form.password"
            :googleRedirectRoute="route('login.social', { provider: 'google' })"
            :azureRedirectRoute="route('login.social', { provider: 'azure' })"
            :linkedinRedirectRoute="route('login.social', { provider: 'linkedin' })"
            :facebookRedirectRoute="route('login.social', { provider: 'facebook' })"
            :forgotPasswordRoute="route('password.request')"
            :registerRoute="route('register')"
            :processing="form.processing"
            :appName="appName"
            :errors="form.errors"
            @submit="submit"
        />
    </SignupLayout>
</template>
